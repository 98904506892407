  "use strict";

  //Detect Mobile
  var isMobile = {
      Android: function() {
          return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
          return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
  };


    $(document).ready(function(){
      $(".perspective").hover3d({
        selector: ".member-img",
        shine: true,
        sensitivity: 10,
      });

    });

     if (!isMobile.any()) {

      // Accepts any class name
      var rellax = new Rellax('.rellax');
  };



(function() {
    // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
    if (!String.prototype.trim) {
        (function() {
            // Make sure we trim BOM and NBSP
            var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
            String.prototype.trim = function() {
                return this.replace(rtrim, '');
            };
        })();
    }

    [].slice.call(document.querySelectorAll('input.input__field, textarea.input__field')).forEach(function(inputEl) {
        // in case the input is already filled..
        if (inputEl.value.trim() !== '') {
            classie.add(inputEl.parentNode, 'input--filled');
        }

        // events:
        inputEl.addEventListener('focus', onInputFocus);
        inputEl.addEventListener('blur', onInputBlur);
    });

    function onInputFocus(ev) {
        classie.add(ev.target.parentNode, 'input--filled');
    }

    function onInputBlur(ev) {
        if (ev.target.value.trim() === '') {
            classie.remove(ev.target.parentNode, 'input--filled');
        }
    }
})();

$(document).ready(function() {
   $(".btn-pager li.disabled a").click(function() {
     return false;
   });
});

// Line Maker

            (function() {
            var lineMaker = new LineMaker({
                    // position: if fixed the lines container will have fixed position.
                    position: 'fixed',
                    lines: [
                        {top: 0, left: '10%', width: 1, height: '100vh', color: '#d9d9d9', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
                        {top: 0, left: '30%', width: 1, height: '100vh', color: '#d9d9d9', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
                        {top: 0, left: '50%', width: 1, height: '100vh', color: '#d9d9d9', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
                        {top: 0, left: '70%', width: 1, height: '100vh', color: '#d9d9d9', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
                        {top: 0, left: '90%', width: 1, height: '100vh', color: '#d9d9d9', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
                    ]
            });

            setTimeout(function() {
                disableButtons();
                lineMaker.animateLinesIn(enableButtons);
            }, 500);

            var ctrls = [].slice.call(document.querySelectorAll('.actions > button'));
            ctrls.forEach(function(ctrl) {
                ctrl.setAttribute('disabled', true);
            });

            function enableButtons() {
                ctrls.forEach(function(ctrl) {
                    ctrl.removeAttribute('disabled');
                });
            }

            function disableButtons() {
                ctrls.forEach(function(ctrl) {
                    ctrl.setAttribute('disabled', true);
                });
            }

            function getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }

            var randomColors = ['#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548','#9E9E9E','#607D8B','#000'],
                randomEasings = ['easeOutExpo','easeOutCirc','easeOutSine','easeOutQuad','easeOutQuart','easeOutQuint','easeInOutExpo','easeInOutCirc','easeInOutSine','easeInOutQuad','easeInOutQuart','easeInOutQuint'],
                totalColors = randomColors.length,
                totalEasings = randomEasings.length;
        })();




$(function () // on document.ready()
{
    if ($('html.has-home').length > 0)
    {


               (function() {
            // Fake loading.
            setTimeout(init, 0);

            function init() {
                // document.body.classList.remove('loading');

                //************************ Example 1 - reveal on load ********************************

                var rev1 = new RevealFx(document.querySelector('#rev-1'), {
                    revealSettings : {
                        bgcolor: '#7f40f1',
                        onCover: function(contentEl, revealerEl) {
                            contentEl.style.opacity = 1;
                        }
                    }
                });
                rev1.reveal();

                var rev2 = new RevealFx(document.querySelector('#rev-2'), {
                    revealSettings : {
                        bgcolor: '#fcf652',
                        delay: 250,
                        direction: 'rl',
                        onCover: function(contentEl, revealerEl) {
                            contentEl.style.opacity = 1;
                        }
                    }
                });
                rev2.reveal();

                //************************ Example 2 - reveal on scroll ********************************

                var scrollElemToWatch_1 = document.getElementById('rev-3'),
                    watcher_1 = scrollMonitor.create(scrollElemToWatch_1, -300),
                    rev3 = new RevealFx(scrollElemToWatch_1, {
                        revealSettings : {
                            bgcolor: '#000000',
                            direction: 'rl',
                            onCover: function(contentEl, revealerEl) {
                                contentEl.style.opacity = 1;
                            }
                        }
                    }),
                    rev4 = new RevealFx(document.querySelector('#rev-4'), {
                        revealSettings : {
                            bgcolor: '#7f40f1',
                            delay: 250,
                            onCover: function(contentEl, revealerEl) {
                                contentEl.style.opacity = 1;
                            }
                        }
                    }),
                    rev5 = new RevealFx(document.querySelector('#rev-5'), {
                        revealSettings : {
                            bgcolor: '#7f40f1',
                            delay: 500,
                            onCover: function(contentEl, revealerEl) {
                                contentEl.style.opacity = 1;
                            }
                        }
                    }),

                    scrollElemToWatch_2 = document.getElementById('rev-6'),
                    watcher_2 = scrollMonitor.create(scrollElemToWatch_2, -300),
                    rev6 = new RevealFx(scrollElemToWatch_2, {
                        revealSettings : {
                            bgcolor: '#fcf652',
                            onCover: function(contentEl, revealerEl) {
                                contentEl.style.opacity = 1;
                            }
                        }
                    }),
                    rev7 = new RevealFx(document.querySelector('#rev-7'), {
                        revealSettings : {
                            bgcolor: '#7f40f1',
                            direction: 'rl',
                            delay: 250,
                            onCover: function(contentEl, revealerEl) {
                                contentEl.style.opacity = 1;
                            }
                        }
                    });
                    // rev8 = new RevealFx(document.querySelector('#rev-8'), {
                    //     revealSettings : {
                    //         bgcolor: '#7f40f1',
                    //         direction: 'rl',
                    //         delay: 500,
                    //         onCover: function(contentEl, revealerEl) {
                    //             contentEl.style.opacity = 1;
                    //         }
                    //     }
                    // }),

                    // scrollElemToWatch_3 = document.getElementById('rev-9'),
                    // watcher_3 = scrollMonitor.create(scrollElemToWatch_3, -300),
                    // rev9 = new RevealFx(scrollElemToWatch_3, {
                    //     revealSettings : {
                    //         bgcolor: '#fcf652',
                    //         direction: 'rl',
                    //         onCover: function(contentEl, revealerEl) {
                    //             contentEl.style.opacity = 1;
                    //         }
                    //     }
                    // }),
                    // rev10 = new RevealFx(document.querySelector('#rev-10'), {
                    //     revealSettings : {
                    //         bgcolor: '#7f40f1',
                    //         delay: 250,
                    //         onCover: function(contentEl, revealerEl) {
                    //             contentEl.style.opacity = 1;
                    //         }
                    //     }
                    // }),
                    // rev11 = new RevealFx(document.querySelector('#rev-11'), {
                    //     revealSettings : {
                    //         bgcolor: '#7f40f1',
                    //         delay: 500,
                    //         onCover: function(contentEl, revealerEl) {
                    //             contentEl.style.opacity = 1;
                    //         }
                    //     }
                    // });

                watcher_1.enterViewport(function() {
                    rev3.reveal();
                    rev4.reveal();
                    rev5.reveal();
                    watcher_1.destroy();
                });
                watcher_2.enterViewport(function() {
                    rev6.reveal();
                    rev7.reveal();
                    // rev8.reveal();
                    watcher_2.destroy();
                });
                // watcher_3.enterViewport(function() {
                //     rev9.reveal();
                //     rev10.reveal();
                //     rev11.reveal();
                //     watcher_3.destroy();
                // });
            }
            })();
    }
});
